.modal {
  padding: 0;
  max-width: 500px;
  border-radius: 8px;
}

.header {
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 14px;

  p {
    color: var(--text-main);
    font-weight: 600;
    font-size: 16px;
  }

  svg {
  }
}
.form {
  padding: 12px 14px;
  border-bottom: 1px solid #efefef;
}
.fieldset {
  border: none;
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  gap: 12px;
  align-items: flex-start;
  margin-bottom: 12px;

  .remove {
    height: fit-content;
    margin-top: calc((44px - 20px) / 2);
  }
}
.inputWrap {
  label {
    font-weight: 600;
  }
}
.select [class*="formSelect__value-container"] {
  font-weight: 500;
}

.footer {
  padding: 1.2rem 14px;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 1rem;
    border-radius: 8px;
    padding: 12px 2rem;
    font-weight: 600;
  }

  button[class*="fill"] {
    font-weight: 400;
  }
}
.errorMsg {
  font-size: 12px;
  color: var(--red);
  display: block;
  margin-top: 8px;
  width: 100%;
}
