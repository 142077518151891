.dropdownIcon {
  width: 20px;
}
.status {
  border-radius: 4px;
  border: 1px solid;
  padding: 6px 4px;
  width: fit-content;
  margin: auto 0;
  width: 90px;
  text-align: center;
  font-weight: 500;
}
.tableBody td {
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 8px;
}
.agent {
  margin-top: 4px;
}
.propertyID {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.email {
  text-transform: lowercase;
  margin-top: 4px;
  display: block;
  color: var(--green);
  text-overflow: ellipsis;
}
.property {
  font-weight: 600;
}
.tableBodyRow * {
  overflow: hidden;
}