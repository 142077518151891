.modal {
  max-width: 800px;
  position: relative;
  padding: 1.5rem;
}
.closeBtn {
  position: absolute;
  right: 1rem;
}
.ttl {
  font-size: 2em;
  margin-bottom: 4px;
}
.id {
  margin-bottom: 1.5rem;
}
.section {
  margin-bottom: 2rem;

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
  }

  &__ttl {
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    background: #fafbfc;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;

    div {
      span {
        color: #a5a3a3;
        margin-bottom: 4px;
        display: block;
      }

      p {
      }

      a {
        color: var(--green);
        text-decoration: underline;
        display: block;
      }
    }
  }
}

.viewBtn {
  color: var(--black);
  font-weight: 600;
  text-decoration: unset;
  color: var(--green);

  svg {
    transform: rotate(-45deg);
    width: 13px;
    margin-left: 4px;

    path {
      fill: var(--green);
    }
  }
}

.btns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  button {
    color: var(--red);
    border-color: var(--red);
  }
}
