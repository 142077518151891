.dropdownIcon {
  width: 20px;
}
.status {
  border-radius: 4px;
  border: 1px solid;
  padding: 6px 4px;
  width: fit-content;
  margin: auto 0;
  width: 90px;
  text-align: center;
  font-weight: 500;
}
.pending {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #f3aa1c;
  color: #f3aa1c;
}
.rejected,
.suspended {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #f03738;
  color: #f03738;
}
.incomplete {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #c2c9d1;
  color: #c2c9d1;
}
.approved {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #3cc13b;
  color: #3cc13b;
}
.tableBody td {
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 8px;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: auto;
}
.propertyID {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tableBodyRow {
  span {
    overflow: hidden;
  }

  // * {
  //   word-break: break-all;
  // }
}
