.filter {
  border: 1px solid #e2e5ea;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  padding: 9px;
}
.filterWrap {
  position: relative;

  > p {
    position: absolute;
    color: var(--green);
    font-weight: 600;
    top: -9px;
    right: -9px;
    background: #fff;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 1px rgb(0 0 0 /15%);
  }
}