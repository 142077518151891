.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  padding: 2rem 1rem;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
}
.head {
  margin-bottom: 1.5rem;
  transform: scale(1.2);
}
.ttl {
  text-align: center;
  font-weight: 600;
  font-size: var(--h5);
  margin-bottom: 2.5rem;
}
.form {
  width: 100%;
}
.forgotPassword {
  background: transparent;
  color: var(--green);
  border: none;
  display: block;
  font-size: 12px;
  margin-top: -8px;
}
.continue {
  width: 100%;
  height: 40px;
  font-size: 14px;
  margin: 3rem 0rem 1rem 0rem;
}
.signup {
  font-size: 13px;
  font-weight: 700;
}
.signup button {
  display: inline;
  margin-left: 6px;
}
.list {
  font-size: 13px;
  color: #808080;
  line-height: 1.5;
}
.list li {
  margin-left: 1.5rem;
}
.back {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  svg {
    transform: rotate(180deg);
    width: 12px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 600px) {
  .body {
    padding: 1rem;
    border-radius: 10px;
  }
  .head {
    transform: unset;
  }
  .ttl {
    font-size: 20px;
  }
}
