*,
::before,
::after {
  box-sizing: border-box;
  transition: 0.3s;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

:root {
  /* Colors */
  --black: #1f1d1e;
  --white: #fafafa;
  --green: #0fc94b;
  --green-disabled: #76f59f;
  --green-bg: #bbfacf;
  --neutral: #808080;
  --red: #ef4747;

  /* Indexes */
  --nav-index: 2;

  /* Font sizes */
  --h1: 54px;
  --h2: 44px;
  --h3: 34px;
  --h4: 26px;
  --h5: 22px;
  --title: 24px;
  --base: 14px;
  --caption: 12px;
  --misc: 10px;

  /* Letter spacing */
  --h1-space: 1.8px;
}

@font-face {
  font-family: "Cronos";
  font-display: swap;
  src: url("./assets/fonts/CronosPro-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white);
  color: var(--black);
  font-size: var(--base);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width and height */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--green);
}

svg,
img {
  width: initial;
  height: auto;
}

.appContainer {
  max-width: 1200px;
  margin: auto;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media screen and (max-width: 1200px) {
  .appContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .appContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

button,
input,
textarea {
  outline: none;
  font-family: inherit;
}

[role="button"] {
  cursor: pointer;
}

[class*="modal-content"] {
  border: none !important;
  border-radius: 16px !important;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: var(--green);
}
