.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 16px;
}
.label {
  font-size: 13px;
  background: transparent;
  padding: 0;
  color: #808080;
  margin-bottom: 10px;
}
.message {
  font-size: 12px;
  color: var(--red);
  display: block;
  margin-top: 8px;
}
.message svg {
  width: 16px;
  margin-right: 4px;
}
.input {
  height: 44px;
  padding-left: 1rem;
  border: 1px solid #efefef;
  border-radius: 10px;
  background: #fcfcfc;
  font-size: 14px;
  padding-right: 10px;
}
.input::placeholder,
.textarea::placeholder {
  color: #757575;
}
.textarea {
  padding-left: 1rem;
  padding-top: 12px;
  margin-top: 10px;
  border: 1px solid #efefef;
  border-radius: 10px;
  background: #fcfcfc;
  font-size: 14px;
  resize: none;
  height: 200px;
}
.icon {
  position: absolute;
  right: 10px;
  top: 2.2rem;
  width: 20px;
  height: auto;
  cursor: pointer;
}
.error label {
  color: var(--red);
}
.error .input {
  background: #fffafa;
}
.req {
  color: var(--red);
  font-weight: 600;
}
