.dropdownIcon {
  width: 22px;
  margin-right: 12px;
}
.dropdownIcon path {
  fill: #000;
}

.status {
  font-weight: 500;

  &.unverified {
    color: #f3aa1c;
  }
  &.suspended {
    color: #f03738;
  }
  &.verified {
    color: #3cc13b;
  }
}

.account {
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    width: 16px;
    min-width: 16px;
  }
}
.email {
  text-transform: lowercase;

  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    width: 16px;
    min-width: 16px;
  }
}
.tableBodyRow * {
  word-break: break-all;
}