.searchContainer {
  border: 1px solid #e2e5ea;
  border-radius: 8px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  background: transparent;

  svg {
    margin-right: 8px;
    width: 18px;
    min-width: 18px;
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    color: var(--black);
    font-weight: 400;
    font-size: 14px;

    &::placeholder {
      color: var(--neutral);
      font-weight: 500;
    }
  }
}
